
<template>
  <div class="main-content">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>


    <div class="row">
      <div class="col-md-3">

        <b-input-group style="margin-top: 30px;" append="Find" class="mb-2 mr-sm-2 mb-sm-0">
          <b-input id="inline-form-input-username" placeholder="Search"></b-input>
        </b-input-group>
      </div>
      <div class="col-md-3">

      </div>
      <div class="col-md-6">
        <b-button style="margin-top: 30px;" class="btn btn-success float-right ml-5 mr-5" v-b-modal.export-customers>Export</b-button>
        <b-button style="margin-top: 30px;" class="btn btn-danger float-right" v-b-modal.add-customer>Add new</b-button>

      </div>
    </div>

    <br/>

    <b-table
            striped hover :items="customers" :fields="columns">

      <template v-slot:cell(options)="data">
        <!-- `data.value` is the value after formatted by the Formatter -->
        <b-dropdown text="Action">
          <b-dropdown-item href="#" >Edit</b-dropdown-item>
          <b-dropdown-item href="#" >Delete</b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>


    <div class="row">
      <div class="col-md-12">
        <hr/>
      </div>
    </div>

    <b-modal  id="add-customer" title="Add Customer" @cancel="true" @ok="validateBeforeSubmitNewCustomer"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <input type="hidden" name="_token" />
        <div class="form-body">

          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Name:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="customer.clientName" />
                </div>
              </div>
            </div>
          </div>
          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Phone number:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="customer.phoneNumber" />
                </div>
              </div>
            </div>
          </div>
          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Email Address:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="customer.emailAddress" />
                </div>
              </div>
            </div>
          </div>

        </div>

      </form>
    </b-modal>
    <b-modal  id="export-customers" title="Export Customers" @cancel="true" @ok="submitExportNewCustomers"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <input type="hidden" name="_token" />
        <div class="form-body">
          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Email Address:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="emailAddress" />
                </div>
              </div>
            </div>
          </div>

        </div>

      </form>
    </b-modal>


  </div>
</template>
<script>

  import { mapGetters, mapActions } from "vuex";

  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css';
  import Multiselect from 'vue-multiselect'

  import DatePicker from 'vue2-datepicker';

  import 'vue2-datepicker/index.css';

export default {
  data() {
    return {
      customers:[],
      customer:{
        id:0,
        clientName:"",
        phoneNumber:"",
        emailAddress:""
      },
      emailAddress:"",
      columns:["clientName","phoneNumber","emailAddress","qbCustomerId"],
      isLoading: false,
      fullPage: true,

    };
  },
  components: {
    Loading,

  },
  mounted (){
    this.getCustomersData();
  },
    methods: {
      ...mapActions(["getCustomers","createCustomer","exportCustomers"]),

      validateBeforeSubmitNewCustomer(){
        this.isLoading = true;

        let self = this;

        this.createCustomer(this.customer).then(() => {
          self.isLoading = false;

          self.getCustomersData();

          self.$bvToast.toast("Customer saved.", {
            title: 'Success',
            variant: 'success',
            autoHideDelay: 5000,
            appendToast: false
          });


        })
            .catch(error => {

              self.isLoading = false;

              self.$bvToast.toast(error.message, {
                title: 'Error',
                variant: 'danger',
                autoHideDelay: 5000,
                appendToast: false
              });


            })
      },validateBeforeSubmitNewCustomer(){
        this.isLoading = true;

        let self = this;

        this.createCustomer(this.customer).then(() => {
          self.isLoading = false;

          self.getCustomersData();

          self.$bvToast.toast("Customer saved.", {
            title: 'Success',
            variant: 'success',
            autoHideDelay: 5000,
            appendToast: false
          });


        })
            .catch(error => {

              self.isLoading = false;

              self.$bvToast.toast(error.message, {
                title: 'Error',
                variant: 'danger',
                autoHideDelay: 5000,
                appendToast: false
              });


            })
      },

      submitExportNewCustomers(){
        this.isLoading = true;

        let self = this;

        this.exportCustomers({emailAddress: this.emailAddress}).then(() => {
          self.isLoading = false;

          self.getCustomersData();

          self.$bvToast.toast("Request received. We'll notify you on email once report is ready", {
            title: 'Success',
            variant: 'success',
            autoHideDelay: 5000,
            appendToast: false
          });


        })
            .catch(error => {

              self.isLoading = false;

              self.$bvToast.toast(error.message, {
                title: 'Error',
                variant: 'danger',
                autoHideDelay: 5000,
                appendToast: false
              });


            })
      },

      validateBeforeSubmitNewCustomer(){
        this.isLoading = true;

        let self = this;

        this.createCustomer(this.customer).then(() => {
          self.isLoading = false;

          self.getCustomersData();

          self.$bvToast.toast("Customer saved.", {
            title: 'Success',
            variant: 'success',
            autoHideDelay: 5000,
            appendToast: false
          });


        })
                .catch(error => {

                  self.isLoading = false;

                  self.$bvToast.toast(error.message, {
                    title: 'Error',
                    variant: 'danger',
                    autoHideDelay: 5000,
                    appendToast: false
                  });


                })
      },

      getCustomersData(){
        let self = this;
        this.getCustomers()
                .then(function (customers) {
                  self.customers = customers;

                })
                .catch(function (error) {



                })
      },
    },
    computed: {
    rows() {
      return this.contacts.length
    }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
